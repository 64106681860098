<template>
  <div class="container">
    <BasicTitle title="Nuevo Mensaje" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Categoría *" />
      <BorderSelect
        v-model="message.data.category"
        label="Categoría *"
        :options="store.state.message.categories"
      />
      <FormError
        :show="message.rules.category"
        message="Seleccione una categoría"
      />
      <BasicLabel label="Destinatario *" />
      <BorderMultiSelect
        v-model="message.data.recipients"
        label="Destinatario *"
        :options="store.state.message.recipients"
      />
      <FormError
        :show="message.rules.recipients"
        message="Seleccione un destinatario"
      />
      <BasicLabel label="Asunto *" />
      <BorderInput
        v-model="message.data.title"
        :disabled="message.disabled"
        label="Asunto *"
      />
      <FormError :show="message.rules.title" message="Ingrese un asunto" />
      <BasicLabel label="Mensaje *" />
      <OutlinedTextArea
        v-model="message.data.text"
        :disabled="message.disabled"
      />
      <FormError :show="message.rules.text" message="Ingrese un texto" />

      <BorderFile
        :v-model="message.selectedDocuments"
        @select="onSelectFiles"
      />
      <FormError />
    </div>
    <SecondaryButton label="Guardar en borrador" :click="() => onSave(false)" />
    <FormError />
    <PrimaryButton label="Enviar" :click="() => onSave(true)" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderMultiSelect from "../../widgets/select/BorderMultiSelect.vue";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import SecondaryButton from "../../widgets/button/SecondaryButton.vue";
import BorderDate from "../../widgets/calendar/BorderDate.vue";
import BorderTime from "../../widgets/calendar/BorderTime.vue";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
// import { openMode } from "../../constants";
import { validation, actions, aws } from "../../constants";
import BorderFile from "../../widgets/file/BorderFile.vue";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderMultiSelect,
    BorderInputPrefix,
    BorderInputPhone,
    BorderDate,
    BorderTime,
    FormError,
    PrimaryButton,
    SecondaryButton,
    BorderFile,
    OutlinedTextArea,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const message = reactive({
      selectedDocuments: [],
      data: {
        id: -1,
        category: "",
        title: "",
        text: "",
        recipients: "",
      },
      rules: {
        category: false,
        title: false,
        text: false,
        recipients: false,
      },
    });

    const onSelectFiles = (ev) => {
      message.selectedDocuments = ev.files;
    };

    const validate = () => {
      let valid = true;

      if (!message.data.category) {
        message.rules.category = true;
        valid = false;
      }
      if (!message.data.title) {
        message.rules.title = true;
        valid = false;
      }
      if (!message.data.text) {
        message.rules.text = true;
        valid = false;
      }
      if (!message.data.recipients) {
        message.rules.recipients = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async (send) => {
      if (!validate()) return;
      store.commit("setLoading", true);

      const responsePath = await store.dispatch(
        actions.documentActions.getPath,
        {
          buildingId: store.state.general.selectedBuilding,
          folderName: `${store.state.auth.user.name} (${store.state.auth.user.email})`,
          documentTypeName: "Mensajes",
        }
      );

      if (responsePath.ok) {
        let count = 0;
        const documents = [];
        const { length } = message.selectedDocuments;
        const finish = async () => {
          const request = {
            buildingId: store.state.general.selectedBuilding,
            userId: store.state.auth.user.id,
            recipients: store.state.message.recipients
              .filter((el) => message.data.recipients.includes(el.id))
              .map((el) => ({ id: el.id, name: el.name })),
            message: message.data.text,
            messageTypeId: message.data.category,
            messageStatusId: send ? 2 : 1,
            messageParentId: null,
            title: message.data.title,
            documents,
          };

          const response = await store.dispatch(
            actions.messageActions.create,
            request
          );
          store.commit("setLoading", false);
          if (response.ok) {
            store.commit("setSelectedInbox", []);
            store.commit("setSelectedSend", []);
            router.push("/mensajes/listado");
          } else {
            alert(response.message);
          }
        };

        if (count === length) finish();

        message.selectedDocuments.forEach(async (doc) => {
          const name = doc.name;
          const key = `${responsePath.path}${name}`;
          const uploaded = async () => {
            documents.push({
              path: responsePath.path,
              name: name
                .split(".")
                .slice(0, -1)
                .join("."),
              ext: validation.extensionRegex.exec(name)[0],
            });

            count += 1;
            if (count === length) finish();
          };
          aws.uploadFile(key, doc, uploaded);
        });
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: responsePath.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      if (!store.state.openMode) router.push("/mensajes/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      store.commit("setLoading", true);
      await store.dispatch(actions.messageActions.categories);
      await store.dispatch(actions.messageActions.recipients, {
        buildingId: store.state.general.selectedBuilding,
        userId: store.state.auth.user.id,
      });
      store.commit("setLoading", false);
      /* if (store.state.openMode === openMode.DETAIL) {
        const selected = store.state.message.selectedNotices[0];
        message.data.id = selected.id;
        message.data.title = selected.title;
        message.data.text = selected.text;
        message.data.indLink = selected.link ? "true" : "";
        message.data.link = selected.link;
        message.disabled = true;
      } */
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (message.data.category) message.rules.category = false;
      if (message.data.title) message.rules.title = false;
      if (message.data.text) message.rules.text = false;
      if (message.data.recipients) message.rules.recipients = false;
    });

    return { store, message, onSave, onSelectFiles };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.link-label {
  margin-right: 1rem;
  font-family: "klavika";
  color: var(--primary);
}
</style>
