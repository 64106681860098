<template>
  <MultiSelect
    :options="options"
    optionLabel="name"
    optionValue="id"
    :placeholder="label"
    :filter="true"
    emptyMessage="No hay datos"
    emptyFilterMessage="No hay resultados"
    class="multiselect-custom select"
  >
    <template #value="slotProps">
      <div v-for="option of slotProps.value" :key="option.id">
        <div>{{ option.name }}</div>
      </div>
      <template v-if="!slotProps.value || slotProps.value.length === 0">
        {{ label }}
      </template>
      <template v-else>
        {{ getValue(slotProps.value) }}
      </template>
    </template>
    <template #option="slotProps">
      <div class="select-item">{{ slotProps.option.name }}</div>
    </template>
  </MultiSelect>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Select",
    },
    options: {
      type: Array,
      default: [],
    },
  },
  setup(props) {
    const getValue = (selected) => {
      if (selected.length === 1) {
        const option = props.options.find((el) => el.id === selected[0]);
        return option.name;
      } else {
        return `${selected.length} seleccionados`;
      }
    };

    return { getValue };
  },
};
</script>

<style scoped>
.select {
  height: 50px;
  width: 350px;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 2px solid var(--primary) !important;
  box-shadow: none !important;
  font-family: "klavika" !important;
  box-shadow: 0 10px 5px -5px rgba(35, 141, 130, 0.15) !important;
  margin-bottom: 0.5rem;
}
.select-item {
  color: var(--primary-dark);
  font-family: "klavika";
}
</style>
